<template>
    <v-app id="inspire">
        <v-navigation-drawer class="bg-indigo-lighten-5" v-model="drawer" location="left" width="290">
            <v-list-item prepend-avatar="https://randomuser.me/api/portraits/men/78.jpg" title="John Leider"></v-list-item>

            <v-divider></v-divider>

            <v-list density="compact" nav>
                <v-list-item prepend-icon="mdi-view-dashboard" title="Home" value="home" to="/"></v-list-item>
                <v-list-item prepend-icon="mdi-view-dashboard" title="Dash" value="home" to="/dash"></v-list-item>
                <v-list-item prepend-icon="mdi-forum" title="About" value="about" to="/about"></v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar color="primary">
            <template v-slot:prepend>
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            </template>
            <v-app-bar-title>Aplikasi Master Barang</v-app-bar-title>
            <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <!-- paste konten disini -->
            <slot />
        </v-main>
    </v-app>
</template>
  
<script>
export default {
    name: 'default-layout',
    data: () => ({
        jam: "",
        drawer: false,
        groupMenu: [],
        user: "",
    }),
    methods: {
        logOut() {
        },
        formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            this.jam = hours + ':' + minutes + ' ' + ampm;
            return this.jam;
        },
    },
    beforeMount() {
        this.formatAMPM(new Date)
    },
}
</script>