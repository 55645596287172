<template>
  <DefaultLayout>
    <v-container class="spacing-playground pa-10" fluid>
      <v-row justify="space-around">
        <v-row dense>
          <v-col v-for="n in 4" :key="n" cols="12">
            <v-card :title="`Content ${n}`" :subtitle="`Subtitle for Content ${n}`"
              text="Lorem ipsum dolor sit amet consectetur, adipisicing elit.?"></v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/default.vue'

export default {
  name: 'HomeView',
  data() {
    return {
    }
  },
  methods: {
  },
  components: {
    DefaultLayout
  },
  beforeMount() {
  },
}
</script>
