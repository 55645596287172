<template>
    <DefaultLayout>
        <v-item-group selected-class="bg-primary">
            <v-container>
                <v-row>
                    <v-col v-for="n in 4" :key="n" cols="6" md="6" align="center">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }">
                            <v-card :class="['d-flex align-center', selectedClass]" dark height="160" width="160"
                                @click="toggle">
                                <div class="text-h6 flex-grow-1 text-center">
                                    {{ isSelected ? 'Selected' : 'Click Me!' }}
                                </div>
                            </v-card>
                        </v-item>
                    </v-col>
                </v-row>
            </v-container>
        </v-item-group>
    </DefaultLayout>
</template>
<script>
import DefaultLayout from '@/layouts/default.vue'
export default {
    name: "DashboardCentral",
    data() {
        return {
        };
    },
    //this one will populate new data set when user changes current page. 
    watch: {
        options: {
            handler() {
            },
        },
        deep: true,
    },
    methods: {
    },
    //this will trigger in the onReady State
    mounted() {
        // this.readDataFromAPI();
    },
    components: {
        DefaultLayout,
    },

}
</script>