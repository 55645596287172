import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'

// createApp(App).use(router).mount('#app')
const app = createApp(App);
app.use(router);
app.use(vuetify);
app.mount('#app');
